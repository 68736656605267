import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { materialTextBoxColors } from '../../theme';

const moveLabel = css`
  top: -14px;
  font-size: 12px;
`;

const MaterialTextBoxStyles = styled.input`
  background: none;
  color: ${materialTextBoxColors.text};
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${materialTextBoxColors.borderBottom};
  padding: 10px 10px 10px 5px;
  width: 100%;
  &:focus {
    outline: none;
    & ~ label.material-text-field__label {
      color: ${props =>
        !props.showError ? materialTextBoxColors.focusLabelText : undefined};
      ${moveLabel};
    }
    & ~ .material-text-field__ripple:before {
      width: 100%;
    }
  }
`;

export default MaterialTextBoxStyles;
