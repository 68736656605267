import React from 'react';
import PropTypes from 'prop-types';
import {
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber
} from 'libphonenumber-js';
import { states } from '../../helpers/States';
import ValidationMessage from '../Validation/ValidationMessage';
import Row from '../Grid/Row';
import Col from '../Grid/Col';
import MaterialTextBox from '../Material/MaterialTextBox';
import MaterialSelect from '../Material/MaterialSelect';
import Button from '../Button/Button';
import ConsentTextMessage from '../Consent/ConsentTextMessage';

const CustomerForm = ({ formikProps, serverError }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue
  } = formikProps;
  const {
    FirstName,
    LastName,
    MobileNumber,
    Email,
    LicensePlate,
    State
  } = values;
  const format = value => {
    return formatIncompletePhoneNumber(value, 'US');
  };
  const formatMobile = event => {
    let value = MobileNumber;
    value = value.replace(/[^\d]/g, '');
    let newValue = parseIncompletePhoneNumber(event.target.value);
    // bug in library that does not delete the last ) of an area code
    // this is small fix to work around it
    if (newValue === value) {
      if (format(newValue).indexOf(event.target.value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1);
      }
    }
    setFieldValue('MobileNumber', format(newValue));
  };

  const globalErrorMessage = serverError && (
    <ValidationMessage error={serverError} />
  );

  return (
    <form onSubmit={handleSubmit}>
      {globalErrorMessage}
      <Row>
        <Col smHalf>
          <MaterialTextBox
            name='FirstName'
            text='First Name'
            onChange={handleChange}
            value={FirstName}
            error={errors.FirstName}
            touched={touched.FirstName}
            onBlur={handleBlur}
            maxLength={100}
          />
        </Col>
        <Col smHalf>
          <MaterialTextBox
            name='LastName'
            text='Last Name'
            onBlur={handleBlur}
            onChange={handleChange}
            value={LastName}
            error={errors.LastName}
            touched={touched.LastName}
            maxLength={100}
          />
        </Col>
        <Col xsFull smHalf>
          <MaterialTextBox
            name='MobileNumber'
            text='Mobile Number'
            type='tel'
            onBlur={handleBlur}
            onChange={formatMobile}
            value={MobileNumber}
            error={errors.MobileNumber}
            touched={touched.MobileNumber}
            maxLength={16}
          />
        </Col>
        <Col xsFull>
          <MaterialTextBox
            name='Email'
            text='Email'
            type='email'
            onBlur={handleBlur}
            onChange={handleChange}
            isOptional={true}
            value={Email}
            error={errors.Email}
            touched={touched.Email}
            maxLength={100}
          />
        </Col>
        <Col xsFull smHalf>
          <MaterialTextBox
            name='LicensePlate'
            text='License Plate'
            onBlur={handleBlur}
            onChange={handleChange}
            value={LicensePlate}
            isOptional={true}
            error={errors.LicensePlate}
            touched={touched.LicensePlate}
            maxLength={20}
          />
        </Col>
        <Col xsFull smHalf>
          <MaterialSelect
            text='State'
            name='State'
            options={states}
            onBlur={handleBlur}
            onChange={handleChange}
            value={State}
            isOptional={true}
            error={errors.State}
            touched={touched.State}
          />
        </Col>
      </Row>
      <ConsentTextMessage />
      <Row justify='space-around'>
        <Button
          value='Submit'
          type='submit'
          isPrimary={true}
          isValid={isValid}
        />
      </Row>
    </form>
  );
};

CustomerForm.propTypes = {
  formikProps: PropTypes.object.isRequired
};

CustomerForm.defaultProps = {
  serverError: false
};

export default CustomerForm;
