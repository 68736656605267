import { css } from '@emotion/core';

import { linkColors } from '../../theme';

const consentStyles = css`
  margin: 3em auto;
  & > * {
    margin-top: 1em;
  }
  .consent-header {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1em;
  }
  a {
    color: ${linkColors.main};
    font-weight: 700;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      color: ${linkColors.state};
      text-decoration: none;
    }
  }
`;

export default consentStyles;
