/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import {
  xsFullStyles,
  xsHalfStyles,
  smHalfStyles,
  colStyles
} from './Col.styles';

const Col = ({ children, xsFull, xsHalf, smHalf, className }) => {
  return (
    <div
      css={[
        colStyles,
        xsFull && xsFullStyles,
        xsHalf && xsHalfStyles,
        smHalf && smHalfStyles
      ]}
      className={className}
    >
      {children}
    </div>
  );
};

Col.propTypes = {
  children: PropTypes.node.isRequired
};

export default Col;
