import { css } from '@emotion/core';
import { infoIconColors } from '../../theme';

const infoStyles = css`
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
  color: ${infoIconColors.main};
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 1rem;
`;

export default infoStyles;
