/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { materialStyled, alertStyled } from './ValidationMessage.styles';

import SrOnly from '../Helpers/SrOnly';

const ValidationMessage = ({ error, isMaterialField }) => {
  return (
    <div
      css={isMaterialField ? materialStyled : alertStyled}
      aria-live='assertive'
      role='alert'
    >
      <SrOnly>Error</SrOnly> {error}
    </div>
  );
};

ValidationMessage.propTypes = {
  error: PropTypes.string.isRequired
};

ValidationMessage.defaultProps = {
  isMaterialField: false
};

export default ValidationMessage;
