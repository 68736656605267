import React from 'react';
import PropTypes from 'prop-types';

import ButtonStyled from './Button.styles';

const Button = ({ value, type, id, onClick, isPrimary, isValid }) => {
  return (
    <ButtonStyled
      primary={isPrimary}
      isDisabled={!isValid}
      id={id}
      type={type}
      value={value}
      onClick={onClick}
      aria-disabled={!isValid ? 'true' : undefined}
    />
  );
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired
};

Button.defaultProps = {
  type: 'button',
  id: null,
  isValid: true,
  onClick: null
};

export default Button;
