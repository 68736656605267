import React, { useReducer } from 'react';
import axios from 'axios';
import isSessionValid from '../../utils/validateSession';
import AuthContext from './authContext';
import authReducer from './authReducer';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOADING,
  SEND_TEXT_ALERT,
  SESSION_TIMEOUT,
  SET_ERROR
} from '../types';

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    isAuthenticated:
      isSessionValid() &&
      localStorage.getItem('token') &&
      JSON.parse(localStorage.getItem('user'))
        ? true
        : null,
    textAlert: null,
    loading: false,
    serverError: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Login User
  const login = async param => {
    setLoading(true);
    try {
      const res = await axios.post(process.env.REACT_APP_AUTH_API_ENDPOINT, {
        pickupKey: param
      });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAIL });
    }
  };

  const setLoading = isLoading =>
    dispatch({ type: SET_LOADING, payload: isLoading });

  const sendTextAlert = async info => {
    setLoading(true);
    if (!validateSession()) return false;
    try {
      await axios.post(process.env.REACT_APP_NOTIF_API_ENDPOINT, {
        message: info.Message,
        lineOfBusiness: 'MKE'
      });
      dispatch({ type: SEND_TEXT_ALERT, payload: info });
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_ERROR,
        payload: "We're sorry an error ocurred. Please try again."
      });
    }
  };

  const validateSession = () => {
    if (!isSessionValid()) {
      dispatch({ type: SESSION_TIMEOUT });
      return false;
    }
    return true;
  };

  // Logout
  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        textAlert: state.textAlert,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        messages: state.messages,
        serverError: state.serverError,
        loading: state.loading,
        login,
        logout,
        sendTextAlert
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
