import { css } from '@emotion/core';
import { materialTextBoxColors } from '../../theme';

const messageStyles = css`
  font-size: 0.6rem;
  padding-left: 5px;
  padding-top: 5px;
`;

const optionalStyles = css`
  color: ${materialTextBoxColors.message};
`;

export { messageStyles, optionalStyles };
