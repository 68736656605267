import * as Yup from 'yup';

const PHONEREGEXP = /^(1\s)?\(\d{3}\)\s\d{3}-\d{4}$/;
const ALPHANUMERICREGEX = /^[a-z0-9]+$/i;
const NAMENOSPACE = /[a-zA-Z'\-.]/;
const NAMEWITHSPACE = /^[a-z A-Z'\-.]+$/;

const CustomerValidationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .trim()
    .matches(NAMENOSPACE, 'First name is invalid')
    .matches(NAMEWITHSPACE, 'First name is invalid')
    .max(100, 'First name must be less than 100 characters')
    .required('First name is required'),
  LastName: Yup.string()
    .trim()
    .matches(NAMENOSPACE, 'Last name is invalid')
    .matches(NAMEWITHSPACE, 'Last name is invalid')
    .max(100, 'Last name must be less than 100 characters')
    .required('Last Name is required'),
  MobileNumber: Yup.string()
    .trim()
    .matches(PHONEREGEXP, 'Mobile number is invalid')
    .required('Mobile number is required'),
  Email: Yup.string()
    .trim()
    .email('Email is invalid')
    .max(100, 'Email must be less than 100 characters'),
  LicensePlate: Yup.string()
    .trim()
    .matches(ALPHANUMERICREGEX, 'License plate is invalid')
    .max(20, 'License plate must be less than 20 characters')
});

export default CustomerValidationSchema;
