/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import materialSelectStyles from './MaterialSelect.styles';

import MaterialField from './MaterialField';

const MaterialSelect = ({
  text,
  name,
  onChange,
  onBlur,
  isOptional,
  options,
  touched,
  error,
  value
}) => {
  const filled = value ? true : false;
  const showError = error && touched ? true : false;
  const errorId = `error${name}`;
  const materialProps = {
    text,
    name,
    isOptional,
    error,
    showError,
    errorId,
    filled,
    value
  };

  return (
    <MaterialField {...materialProps} filled={filled}>
      <select
        css={materialSelectStyles}
        id={name}
        name={name}
        onChange={event => {
          onChange(event);
        }}
        onBlur={onBlur}
        aria-required={!isOptional}
        aria-invalid={showError ? showError : undefined}
        aria-describedby={errorId}
        value={value ? value : ''}
      >
        <option value='' disabled=''>
          &nbsp;
        </option>
        {options.map(option => (
          <option
            key={option.id ? option.id : option.value}
            value={option.value}
          >
            {option.text}
          </option>
        ))}
      </select>
    </MaterialField>
  );
};

MaterialSelect.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

MaterialSelect.defaultProps = {
  touched: null,
  isOptional: false,
  error: null
};

export default MaterialSelect;
