import { css } from '@emotion/core';

const formStyles = css`
  .col-spacing > div {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  }
  .button-row {
    margin-top: 4em;
  }

  h3 {
    margin-top: 1rem;
  }

  h4 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .message-preview {
    word-break: break-word;
  }
`;

export default formStyles;
