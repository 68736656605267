import React, { useContext, Fragment } from 'react';

import SmsContext from '../../context/sms/smsContext';
import { Formik } from 'formik';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CustomerValidationSchema from './CustomerValidationSchema';

import InitialStyled from './Initial.styles';

import CustomerForm from './CustomerForm';
import Loading from '../Loading/Loading';

const Initial = () => {
  const smsContext = useContext(SmsContext);
  const { addSmsInfo, loading, serverError } = smsContext;
  const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

  const loadingAnimation = loading && <Loading />;

  return (
    <Fragment>
      <InitialStyled loading={loading ? 'true' : undefined}>
        <h1>Get Milwaukee Parking Alerts</h1>
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaPublicKey}>
          <Formik
            initialValues={{
              FirstName: '',
              LastName: '',
              MobileNumber: '',
              Email: '',
              LicensePlate: '',
              State: ''
            }}
            validationSchema={CustomerValidationSchema}
            onSubmit={async (values, { setErrors }) => {
              let castValues = CustomerValidationSchema.cast(values);
              castValues.RecaptchaToken = values.RecaptchaToken = await window.grecaptcha.execute(
                recaptchaPublicKey,
                {
                  action: 'TextAlertsSignUp'
                }
              );
              addSmsInfo(castValues, setErrors);
            }}
          >
            {props => (
              <CustomerForm formikProps={props} serverError={serverError} />
            )}
          </Formik>
        </GoogleReCaptchaProvider>
      </InitialStyled>
      {loadingAnimation}
    </Fragment>
  );
};

export default Initial;
