import React, { useReducer } from 'react';
import SmsContext from './smsContext';
import smsReducer from './smsReducer';
import { ADD_SMS_INFO, SET_ERROR, SET_LOADING, CLEAR_STATE } from '../types';
import axios from 'axios';

const SmsState = props => {
  const initialState = {
    smsInfo: null,
    loading: false,
    serverError: null
  };

  const [state, dispatch] = useReducer(smsReducer, initialState);

  // Sign up for Sms alerts
  const addSmsInfo = async (info, setErrors) => {
    setLoading(true);
    let customerInfo = { ...info };
    // remove () and spaces from string
    customerInfo.MobileNumber = customerInfo.MobileNumber.replace(/[^\d]/g, '');

    // remove the international number from the mobile number
    if (customerInfo.MobileNumber.length === 11) {
      customerInfo.MobileNumber = customerInfo.MobileNumber.substr(1);
    }
    try {
      await axios.post(process.env.REACT_APP_API_ENDPOINT, customerInfo);
      dispatch({ type: ADD_SMS_INFO, payload: info });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setFormErrors(error.response.data.errors, setErrors);
      } else {
        setGlobalErrors(
          "We're sorry you are having trouble. If you continue to have issues signing up for alerts, please call 414-344-0840 for assistance. Thank you."
        );
      }
      setLoading(false);
    }
  };

  // Errors must be formatted in the same format that as formik
  const setFormErrors = (error, setErrors) => {
    let tempErrors = {};
    Object.keys(error).map(
      errorField => (tempErrors[errorField] = error[errorField][0])
    );
    setErrors(tempErrors);
  };

  const setGlobalErrors = error => {
    dispatch({ type: SET_ERROR, payload: error });
  };

  const setLoading = isLoading =>
    dispatch({ type: SET_LOADING, payload: isLoading });

  const clearState = () => dispatch({ type: CLEAR_STATE });

  return (
    <SmsContext.Provider
      value={{
        smsInfo: state.smsInfo,
        loading: state.loading,
        serverError: state.serverError,
        addSmsInfo,
        setLoading,
        clearState
      }}
    >
      {props.children}
    </SmsContext.Provider>
  );
};

export default SmsState;
