/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import srOnlyStyles from './SrOnly.styles';

const SrOnly = ({ children }) => {
  return <div css={srOnlyStyles}>{children}</div>;
};

SrOnly.propTypes = {
  children: PropTypes.node.isRequired
};

export default SrOnly;
