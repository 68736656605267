import { css } from '@emotion/core';
import { globalAlertColors, mainColors } from '../../theme';

const materialStyled = css`
  font-size: 0.7rem;
  color: ${mainColors.error};
`;

const alertStyled = css`
  color: ${globalAlertColors.text};
  background-color: ${globalAlertColors.bg};
  border-color: ${globalAlertColors.border};
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`;

export { materialStyled, alertStyled };
