import * as Yup from 'yup';

let startDate = new Date();
// yup does not include current date
startDate.setDate(startDate.getDate() - 1);
let endDate = new Date();
endDate.setDate(endDate.getDate() + 180);

const CustomerValidationSchema = Yup.object().shape({
  Message: Yup.string().required('Message is required'),
  Date: Yup.date().when('Message', {
    is: Message => /{date}/.test(Message),
    then: Yup.date()
      .required('Date is required')
      .min(
        startDate,
        `Date must be at least ${new Date().toLocaleDateString()}`
      )
      .max(endDate, `Date must be at most ${endDate.toLocaleDateString()}`)
  }),
  Time: Yup.date().when('Message', {
    is: Message => /{time}/.test(Message),
    then: Yup.date().required('Time is required')
  })
});

export default CustomerValidationSchema;
