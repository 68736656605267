import { css } from '@emotion/core';

const successStyles = css`
  h2 {
    margin-bottom: 1em;
  }
  h3 {
    font-size: 1.1rem;
  }
  .label {
    font-size: 0.89em;
    color: #4a4a4a;
    margin: 3em auto 1em;
  }
  .value {
    font-size: 1.26em;
    color: #080f19;
    word-break: break-word;
  }
`;

export default successStyles;
