/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { messageStyles, optionalStyles } from './ValidationInput.styles';

import ValidationMessage from './ValidationMessage';

const ValidationInput = ({ isOptional, showError, error, id }) => {
  // Only show Optional text when needed and when then there is no error message to be shown to the user
  const optionalElem = isOptional && !showError && (
    <span css={optionalStyles}>Optional</span>
  );

  const errorElem = showError && (
    <ValidationMessage error={error} isMaterialField={true} />
  );

  return (
    <div css={messageStyles} id={id}>
      {optionalElem}
      {errorElem}
    </div>
  );
};

ValidationInput.propTypes = {
  showError: PropTypes.bool.isRequired
};

ValidationInput.defaultProps = {
  isOptional: false,
  id: null,
  error: null
};

export default ValidationInput;
