import { css } from '@emotion/core';
import { navColors, usernameColors } from '../../theme';

import Banner from '../../assets/banner.jpg';

const navStyles = css`
  background: ${navColors.bg};
  .nav-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .logo-block {
      max-width: 170px;
      max-height: 170px;

      img {
        width: 100%;
        height: auto;
      }

      @media (min-width: 576px) {
        margin-left: 1em;
      }

      @media (min-width: 992px) {
        margin-left: 6em;
      }
    }
    .logged-in-block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      .username {
        display: none;
        color: ${usernameColors.text};
        @media (min-width: 576px) {
          display: block;
          padding-right: 2em;
          margin-left: 1em;
        }
      }
      @media (min-width: 576px) {
        margin-right: 2em;
      }
    }
  }

  .banner {
    margin-bottom: 2rem;
    height: 70px;
    width: 100%;
    background: url(${Banner});
    background-position: 20%, center;
    @media (min-width: 992px) {
      background-size: cover;
    }
  }
`;

export default navStyles;
