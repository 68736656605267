import React, {
  useContext,
  Fragment,
  useRef,
  useState
} from 'react';
import AuthContext from '../../context/auth/authContext';
import Initial from '../Dashboard/Initial';
import Success from '../Dashboard/Success';
import Loading from '../Loading/Loading';
import SessionTimeOut from '../SessionTimeOut/SessionTimeOut';
import IdleTimer from 'react-idle-timer';

const Dashboard = props => {
  const authContext = useContext(AuthContext);
  const {loading, textAlert, logout } = authContext;
  const idleTimer = useRef();
  const [isUserIdle, setIdle] = useState(false);
  const idleTimerEvents = [];

  const resetIdle = () => {
    idleTimer.current.reset();
    setIdle(false);
  };

  const onIdle = () => setIdle(true);

  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <IdleTimer
        ref={idleTimer}
        events={idleTimerEvents}
        onIdle={onIdle}
        timeout={1000 * 60 * 9}
      />
      {textAlert ? <Success /> : <Initial />}
      {isUserIdle && (
        <SessionTimeOut
          resetIdle={resetIdle}
          logout={logout}
          history={props.history}
        />
      )}
    </Fragment>
  );
};

export default Dashboard;
