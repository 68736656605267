import { css } from '@emotion/core';
import { keyframes } from '@emotion/core';

import { loadingColors } from '../../theme';

const circle = keyframes`
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
`;

const loadingStyles = css`
  justify-content: center;
  align-items: center;
  height: 50vh;
  display: flex;
  .text {
    color: ${loadingColors.text};
    margin-bottom: 0.5rem;
  }
  .animation {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    div {
      position: absolute;
      width: 4px;
      height: 4px;
      background: ${loadingColors.circlesBg};
      border-radius: 50%;
      animation-name: ${circle};
      animation-duration: 1.2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
`;

const dotsStyles = [
  css`
    animation-delay: 0s;
    top: 29px;
    left: 53px;
  `,
  css`
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
  `,
  css`
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
  `,
  css`
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
  `,
  css`
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
  `,
  css`
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
  `,
  css`
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
  `,
  css`
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
  `,
  css`
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
  `,
  css`
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
  `,
  css`
    animation-delay: -1s;
    top: 50px;
    left: 41px;
  `,
  css`
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
  `
];

export { dotsStyles, loadingStyles };
