import { css } from '@emotion/core';
import { successColors } from '../../theme';

const successStyles = css`
  h2 {
    margin-bottom: 1em;
  }
  h3 {
    font-size: 1.1rem;
  }
  .label {
    font-size: 0.89em;
    color: ${successColors.label};
    margin: 3em auto 1em;
  }
  .value {
    font-size: 1.26em;
    color: ${successColors.value};
    word-break: break-word;
  }
  .text-notification {
    margin: 3em auto;
    color: ${successColors.textNotification};
    font-size: 1.03em;
  }
`;

export default successStyles;
