import { format, isValid } from 'date-fns';

const formatMessage = (message, time, date) =>
  message
    .replace(
      '{time}',
      time && isValid(time) ? format(time, 'h:mm aa') : '__________'
    )
    .replace(
      '{date}',
      date && isValid(date) ? format(date, 'MM/dd/yyyy') : '__________'
    );

export default formatMessage;
