import { css } from '@emotion/core';
import { footerColors } from '../../theme';

const footerStyles = css`
  background: ${footerColors.bg};
  color: ${footerColors.text};
  padding-top: 2em;
  padding-bottom: 2em;
  font-size: 0.9rem;
  .box--footer__links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a,
    a:hover {
      color: ${footerColors.link};
      font-weight: 700;
    }
    .separator {
      display: inline-block;
      margin: auto 10px;
    }
  }
  .copyright {
    padding: 1.5rem 0rem;
    font-size: 0.8rem;
  }
  .translate {
    text-align: center;
  }
`;

export default footerStyles;
