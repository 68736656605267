import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOADING,
  SEND_TEXT_ALERT,
  SESSION_TIMEOUT,
  SET_ERROR
} from '../types';
import setAuthToken from "../../utils/setAuthToken";

export default (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const {
        accessToken,
        category,
        email,
        firstName,
        lastName
      } = action.payload;
      const user = {
        name: `${firstName} ${lastName}`,
        email,
        category
      };
      localStorage.clear();
      localStorage.setItem('token', accessToken);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('timeOut', new Date());
      setAuthToken(accessToken);
      return {
        ...state,
        token: action.payload.accessToken,
        user: user,
        isAuthenticated: true,
        loading: false
      };
    case SEND_TEXT_ALERT:
      return {
        ...state,
        textAlert: action.payload,
        serverError: null,
        loading: false
      };
    case SET_ERROR: {
      return {
        ...state,
        serverError: action.payload,
        loading: false
      };
    }
    case LOGIN_FAIL:
    case LOGOUT:
    case SESSION_TIMEOUT:
      localStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        textAlert: null,
        serverError: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
