import React, { useContext, Fragment } from 'react';
import AuthContext from '../../context/auth/authContext';
import { Formik } from 'formik';

import InitialStyled from './Initial.styles';

import ValidationSchema from './ValidationSchema';
import Form from './Form';
import Loading from '../Loading/Loading';

const Initial = () => {
  const authContext = useContext(AuthContext);
  const { sendTextAlert, loading, serverError } = authContext;

  const loadingAnimation = loading && <Loading />;

  return (
    <Fragment>
      <InitialStyled loading={loading ? 'true' : undefined}>
        <h1>Text Alert</h1>
        <Formik
          initialValues={{
            Message: '',
            Date: '',
            Time: ''
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values, { setErrors }) => {
            sendTextAlert(values, setErrors);
          }}
        >
          {props => (
            <Form
              formikProps={props}
              serverError={serverError}
            />
          )}
        </Formik>
      </InitialStyled>
      {loadingAnimation}
    </Fragment>
  );
};

export default Initial;
