// Sign up for text alerts
export const ADD_SMS_INFO = 'ADD_SMS_INFO';
export const CLEAR_STATE = 'CLEAR_STATE';

// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';
export const LOGOUT = 'LOGOUT';
export const SEND_TEXT_ALERT = 'SEND_TEXT_ALERT';

// Shared
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
