import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from '../../utils/formatMessage';

export const Preview = React.memo(({ Message, Time, Date }) => (
  <div className='message-preview'>{formatMessage(Message, Time, Date)}</div>
));

Preview.propTypes = {
  Message: PropTypes.string.isRequired,
  Time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  Date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
};
