/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import validationMessageBlockStyles from './AdditionalFields.styles';

import Row from '../Grid/Row';
import Col from '../Grid/Col';
import Button from '../Button/Button';
import { Preview } from './Preview';
import ValidationMessage from '../Validation/ValidationMessage';

export const AdditionalFields = ({
  Message,
  Date,
  Time,
  setFieldValue,
  errors,
  touched,
  setFieldTouched
}) => {
  const isDateNeeded = /{date}/g.test(Message) ? true : false;

  const isTimeNeeded = /{time}/g.test(Message) ? true : false;

  const startDate = new window.Date();

  let endDate = new window.Date();
  endDate.setDate(endDate.getDate() + 180);

  const dateError = errors.Date && touched.Date && (
    <div css={validationMessageBlockStyles}>
      <ValidationMessage isMaterialField={true} error={errors.Date} />
    </div>
  );

  const timeError = errors.Time && touched.Time && (
    <div css={validationMessageBlockStyles}>
      <ValidationMessage isMaterialField={true} error={errors.Time} />
    </div>
  );

  const CustomButton = ({ value, onClick }) => (
    <Button
      value={value ? value : 'Select'}
      isPrimary={true}
      isValid={true}
      onClick={onClick}
    />
  );

  const handleChange = (field, value) => {
    setFieldTouched(field, true);
    setFieldValue(field, value);
  };

  const dateCol = isDateNeeded && (
    <Col xsHalf>
      <h4>Date</h4>
      <DatePicker
        id='date'
        name='date'
        onChange={date => handleChange('Date', date)}
        selected={Date}
        minDate={startDate}
        maxDate={endDate}
        dateFormat='MM/dd/yyyy'
        customInput={<CustomButton />}
      />
      {dateError}
    </Col>
  );

  const timeCol = isTimeNeeded && (
    <Col xsHalf>
      <h4>Time</h4>
      <DatePicker
        id='time'
        name='time'
        onChange={time => handleChange('Time', time)}
        showTimeSelect
        selected={Time}
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption='Time'
        dateFormat='h:mm aa'
        customInput={<CustomButton />}
      />
      {timeError}
    </Col>
  );

  return (
    <Row className='col-spacing'>
      {dateCol}
      {timeCol}
      <Col>
        <h3>Preview</h3>
      </Col>
      <Col>
        <Preview Message={Message} Time={Time} Date={Date} />
      </Col>
    </Row>
  );
};

AdditionalFields.propTypes = {
  Message: PropTypes.string.isRequired,
  Time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  Date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  setFieldValue: PropTypes.func.isRequired
};
