import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { materialFieldColors } from '../../theme';

const error = css`
  select,
  input {
    padding-right: 1.7rem;
    border-color: ${materialFieldColors.invalid};
  }
  .material-text-field__label {
    color: ${materialFieldColors.invalid};
  }

  .material-text-field__ripple {
    display: none;
  }
`;

const MaterialFieldStyles = styled.div`
  position: relative;
  margin-top: 2.5rem;
  label {
    color: ${materialFieldColors.label};
    font-size: ${props => (props.filled ? '12px' : '16px')};
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: ${props => (props.filled ? '-14px' : '10px')};
    transition: 300ms ease all;
  }
  .material-text-field__ripple {
    position: relative;
    display: block;
    width: 100%;
  }
  .material-text-field__ripple:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: ${materialFieldColors.ripple};
    transition: 300ms ease all;
    left: 0%;
  }

  .fa-info-circle {
    color: ${materialFieldColors.invalid};
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 1rem;
  }
  ${props => (props.showError ? error : undefined)}
`;

export default MaterialFieldStyles;
