import React, { useContext, useEffect, useRef } from 'react';
import AuthContext from '../../context/auth/authContext';
import Loading from '../Loading/Loading';

const Auth = props => {
  const { match } = props;
  const authContext = useContext(AuthContext);
  const { login, isAuthenticated } = authContext;
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      login(match.params.GUID);
    } else {
      if (isAuthenticated) {
        props.history.push('/dashboard');
      } else {
        const propsTemp = {
          title: 'We are sorry',
          description: `We are unable to authenticate you. Try one of these options:`,
          link: (
            <a
              href={process.env.REACT_APP_CALM_LOGIN}
              rel='noopener noreferrer'
            >
              Go to Login
            </a>
          )
        };
        props.history.push({
          pathname: '/error',
          appState: {
            ...propsTemp
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return <Loading />;
};

export default Auth;
