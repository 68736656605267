import { css } from '@emotion/core';

const modalStyles = css`
  .rodal,
  .rodal-mask {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: fixed !important;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rodal-dialog {
    display: flex;
    margin: auto;
    height: auto !important;
    flex-direction: column;
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 1.25em;
    border-radius: 0.3125em;
    box-sizing: border-box;
    justify-content: center;
    position: relative !important;
    width: 32em;
    max-width: 100%;
  }
  .header,
  .body {
    margin-bottom: 35px;
    text-align: center;
  }
  .header {
    font-size: 1.7rem;
  }

  .body {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export default modalStyles;
