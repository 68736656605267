export const mainColors = {
  baseText: '#080f19',
  pageBg: '#ffffff',
  boxBorder: '#a7a7a7',
  primary: '#007cad',
  white: '#ffffff',
  error: '#ba0000',
  safariTapHighlight: 'transparent'
};

export const navColors = {
  bg: '#166085'
};

export const mainContentColors = {
  bg: mainColors.white
};

export const footerColors = {
  bg: '#166085',
  text: mainColors.white,
  link: mainColors.white
};

export const buttonColors = {
  primaryBg: mainColors.primary,
  primaryBorder: mainColors.primary,
  primaryText: mainColors.white,
  primaryDisabledBg: '#949494',
  primaryDisabledBorder: '#949494',
  primaryDisabledText: mainColors.white,
  secondaryBg: mainColors.white,
  secondaryBorder: mainColors.primary,
  secondaryText: mainColors.primary
};

export const linkColors = {
  main: mainColors.primary,
  state: '#00a1e0'
};

export const loadingColors = {
  text: mainColors.primary,
  circlesBg: mainColors.primary
};

export const materialFieldColors = {
  label: '#4a4a4a',
  invalid: mainColors.error,
  ripple: mainColors.primary
};

export const materialTextBoxColors = {
  text: mainColors.baseText,
  focusLabelText: mainColors.primary,
  borderBottom: '#4a4a4a'
};

export const materialSelectColors = {
  text: mainColors.baseText,
  focusLabelText: mainColors.primary,
  textShadow: '#000',
  borderBottom: '#4a4a4a'
};

export const infoIconColors = {
  main: materialFieldColors.invalid
};

export const materialLabelColors = {
  main: '#4a4a4a'
};

export const materialRippleColors = {
  bg: mainColors.primary
};

export const textAreaColors = {
  invalid: mainColors.error
};

export const globalAlertColors = {
  text: '#721c24',
  bg: '#f8d7da',
  border: '#f5c6cb'
};

export const successColors = {
  label: '#4a4a4a',
  value: '#080f19',
  textNotification: '#080f19'
};

export const agentBoxColors = {
  headerText: '#ffffff',
  headerBg: mainColors.primary,
  border: '#979797'
};

export const usernameColors = {
  text: mainColors.white
};

export const fonts = {
  base: '1rem'
};
