/** @jsx jsx */
import { jsx } from '@emotion/core';

import footerStyles from './Footer.styles';

import Container from '../Grid/Container';

import Pdf from '../../assets/Privacy-Notice.pdf';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer css={footerStyles}>
      <Container>
        <div className='box--footer__links'>
          <div>
            <a
              href={Pdf}
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
            <span className='separator'>|</span>
            <a
              href='https://duncansolutions.com/terms-of-use/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className='copyright'>
          © Copyright {year} Duncan Solutions. All rights reserved.
        </div>
        <div className='translate' id="google_translate_element" />
      </Container>
    </footer>
  );
};

export default Footer;
