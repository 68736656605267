/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

import modalStyles from './SessionTimeOut.styles';

import Button from '../Button/Button';

const SessionTimeOut = ({ resetIdle, logout, history }) => {
  const [visible, setVisible] = useState(true);
  const LOGOUT_TIME = 1000 * 60;

  useEffect(() => {
    let timer = setTimeout(() => {
      handleLogout();
    }, LOGOUT_TIME);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    setVisible(false);
    logout();
    history.push('/logout');
  };

  return (
    <div css={modalStyles}>
      <Rodal
        visible={visible}
        onClose={handleLogout}
        closeMaskOnClick={false}
        showCloseButton={false}
        animation='fade'
      >
        <div className='header'>Are you still there?</div>
        <div className='body'>
          Your session is about to expire. You will be logged out due to
          inactivity in 60 seconds.
        </div>
        <div className='footer'>
          <Button isPrimary={false} onClick={handleLogout} value={'Logout'} />
          <Button isPrimary={true} onClick={resetIdle} value='Continue' />
        </div>
      </Rodal>
    </div>
  );
};

export default SessionTimeOut;
