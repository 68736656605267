/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';

import SmsContext from '../../context/sms/smsContext';

import successStyles from './Success.styles';

import Row from '../Grid/Row';
import Col from '../Grid/Col';

import { states } from '../../helpers/States';

const Success = () => {
  const smsContext = useContext(SmsContext);
  const { smsInfo } = smsContext;
  const {
    FirstName,
    LastName,
    MobileNumber,
    Email,
    LicensePlate,
    State
  } = smsInfo;

  const stateSelected = State ? states.filter(st => st.value === State) : null;

  return (
    <div css={successStyles}>
      <h2>Parking Alerts — Success</h2>
      <h3>THANK YOU FOR SIGNING UP FOR ALERTS.</h3>
      <Row>
        <Col smHalf>
          <p className='label'>First Name</p>
          <p className='value'>{FirstName}</p>
        </Col>
        <Col smHalf>
          <p className='label'>Last Name</p>
          <p className='value'>{LastName}</p>
        </Col>
        <Col smHalf>
          <p className='label'>Mobile</p>
          <p className='value'>{MobileNumber}</p>
        </Col>
        {Email && (
          <Col xsFull>
            <p className='label'>Email</p>
            <p className='value'>{Email}</p>
          </Col>
        )}
        {LicensePlate && (
          <Col smHalf>
            <p className='label'>License Plate</p>
            <p className='value'>{LicensePlate}</p>
          </Col>
        )}
        {stateSelected && (
          <Col smHalf>
            <p className='label'>State</p>
            <p className='value'>{stateSelected[0].text}</p>
          </Col>
        )}
      </Row>
      <Row>
        <Col xsFull className='text-notification'>
          Check your text messages for a welcome message.
        </Col>
      </Row>
    </div>
  );
};

export default Success;
