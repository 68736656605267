import styled from '@emotion/styled';

const initialStyles = styled.div`
  display: ${props => (props.loading ? 'none' : 'block')};
  h1 {
    margin-top: 0em;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1em;
  }
`;

export default initialStyles;
