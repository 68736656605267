/** @jsx jsx */
import { jsx } from '@emotion/core';
import 'normalize.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Global } from '@emotion/core';
import FullStory from 'react-fullstory';

import SmsState from './context/sms/SmsState';
import AuthState from './context/auth/AuthState';

import { globalStyles, homeStyles } from './App.styles';

import Customer from './components/pages/Customer';
import Error from './components/pages/Error';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Container from './components/Grid/Container';
import Dashboard from './components/pages/Dashboard';
import Auth from './components/pages/Auth';
import PrivateRoute from './components/routing/PrivateRoute';

import setAuthToken from './utils/setAuthToken';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const fullStory = process.env.REACT_APP_FULLSTORY_ORG && (
    <FullStory org={process.env.REACT_APP_FULLSTORY_ORG} />
  );

  return (
    <SmsState>
      <AuthState>
        <HashRouter>
          <Global styles={globalStyles} />
          <div css={homeStyles}>
            {fullStory}
            <Header />
            <Container>
              <div className='block-spacing'>
                <Switch>
                  <Route exact path='/' component={Customer} />
                  <Route exact path='/auth/:GUID' component={Auth} />
                  <PrivateRoute exact path='/dashboard' component={Dashboard} />
                  <Route component={Error} />
                </Switch>
              </div>
            </Container>
            <Footer />
          </div>
        </HashRouter>
      </AuthState>
    </SmsState>
  );
}

export default App;
