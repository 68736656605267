/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import AuthContext from '../../context/auth/authContext';

import successStyles from './Success.styles';

import Row from '../Grid/Row';
import Col from '../Grid/Col';
import formatMessage from '../../utils/formatMessage';

const Success = () => {
  const authContext = useContext(AuthContext);
  const { textAlert } = authContext;
  const { Message, Date, Time } = textAlert;
  const formattedMessage = formatMessage(Message, Time, Date);

  return (
    <div css={successStyles}>
      <h2>Text Alert — Success</h2>
      <h3>YOUR MESSAGE HAS BEEN SENT</h3>
      <Row>
        <Col xsFull>
          <p className='label'>Text</p>
          <p className='value'>{formattedMessage}</p>
        </Col>
      </Row>
    </div>
  );
};

export default Success;
