import { isValid } from 'date-fns';
const isSessionValid = () => {
  // Time is stored in milliseconds, we want to timeout after 30 minutes
  const minutes = 60000 * 30;
  const saved = new Date(localStorage.getItem('timeOut'));
  const currentTime = new Date();
  if (!saved || !isValid(saved) || currentTime.getTime() - saved > minutes) {
    localStorage.clear();
    return false;
  }
  return true;
};

export default isSessionValid;
