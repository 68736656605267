/** @jsx jsx */
import { jsx } from '@emotion/core';

import { dotsStyles, loadingStyles } from './Loading.styles.js';

const Loading = () => {
  const dots = dotsStyles.map((dot, index) => (
    <div key={index} css={dot}></div>
  ));

  return (
    <div css={loadingStyles}>
      <div>
        <div className='text'>Loading</div>
        <div className='animation'>{dots}</div>
      </div>
    </div>
  );
};

export default Loading;
