import { css } from '@emotion/core';
import { mainColors, fonts } from './theme';

const globalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-family: 'Lato', sans-serif;
    font-size: ${fonts.base};
    color: ${mainColors.baseText};
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: ${mainColors.safariTapHighlight};
  }
  body {
    background: ${mainColors.pageBg};
  }
`;

const homeStyles = css`
  height: 100%;
  min-height: 100vh;
  width: 100%;

  .block-spacing {
    margin: 4em auto;
    min-height: 60vh;
  }
`;

export { globalStyles, homeStyles };
