import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { buttonColors } from '../../theme';

const primary = css`
  color: ${buttonColors.primaryText};
  background: ${buttonColors.primaryBg};
  border-color: ${buttonColors.Border};
`;

const primaryDisabled = css`
  color: ${buttonColors.primaryDisabledText};
  background: ${buttonColors.primaryDisabledBg};
  border-color: ${buttonColors.primaryDisabledBorder};
`;

const ButtonStyled = styled.input`
  text-transform: uppercase;
  cursor: pointer;
  padding: 0.6rem 1.5rem;
  font-weight: 700;
  height: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  min-width: 120px;
  color: ${buttonColors.secondaryText};
  background: ${buttonColors.secondaryBg};
  border: 1px solid ${buttonColors.secondaryBorder};
  ${props => {
    return props.isDisabled && props.primary
      ? primaryDisabled
      : props.primary
      ? primary
      : undefined;
  }}
`;

export default ButtonStyled;
