/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import SmsContext from '../../context/sms/smsContext';
import AuthContext from '../../context/auth/authContext';

import navStyles from './Header.styles';

import Logo from '../../assets/logo.png';
import Button from '../Button/Button';

const Header = () => {
  const smsContext = useContext(SmsContext);
  const onClick = () => smsContext.clearState();

  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout, user } = authContext;

  const authenticated = isAuthenticated && (
    <div className='logged-in-block'>
      {user && <div className='username'>Hello, {user.name}</div>}
      <Link to={'/logout'}>
        <Button isPrimary={false} onClick={logout} value='logout' />
      </Link>
    </div>
  );

  return (
    <nav css={navStyles}>
      <div className='nav-flex'>
        <div className='logo-block'>
          <Link to={isAuthenticated ? '/dashboard' : '/'} onClick={onClick}>
            <img src={Logo} alt='Logo' />
          </Link>
        </div>
        {authenticated}
      </div>
      <div className='banner' />
    </nav>
  );
};

export default Header;
