import { css } from '@emotion/core';

import { materialSelectColors } from '../../theme';

const moveLabel = css`
  top: -14px;
  font-size: 12px;
`;

const materialSelectStyles = css`
  background: none;
  color: ${materialSelectColors.text};
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${materialSelectColors.borderBottom};
  padding: 10px 35px 10px 5px;
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, white, white);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:-moz-focusing {
    color: transparent;
    text-shadow: 0 0 0 ${materialSelectColors.textShadow};
  }
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;

    background-image: linear-gradient(45deg, #007cad 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #007cad 50%),
      linear-gradient(to right, white, white);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    & ~ label.material-text-field__label {
      color: ${materialSelectColors.focusLabelText};
      ${moveLabel}
    }
    & ~ .material-text-field__ripple:before {
      width: 100%;
    }
  }
`;

export default materialSelectStyles;
