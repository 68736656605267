import React from 'react';
import PropTypes from 'prop-types';

import MaterialFieldStyles from './MaterialField.styles';

import ValidationInput from '../Validation/ValidationInput';
import InfoIcon from '../Helpers/InfoIcon';

const MaterialField = ({
  text,
  name,
  isOptional,
  error,
  children,
  showError,
  errorId,
  filled
}) => {
  let errorIcon = showError && <InfoIcon />;
  return (
    <MaterialFieldStyles showError={showError} filled={filled}>
      {children}
      {errorIcon}
      <span className='material-text-field__ripple'></span>
      <label className='material-text-field__label' htmlFor={name}>
        {text}
      </label>
      <ValidationInput
        id={errorId}
        isOptional={isOptional}
        showError={showError}
        error={error}
      />
    </MaterialFieldStyles>
  );
};

MaterialField.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  errorId: PropTypes.string.isRequired
};

MaterialField.defaultProps = {
  error: null,
  showError: null,
  filled: false
};

export default MaterialField;
