import React from 'react';
import PropTypes from 'prop-types';

import MaterialField from './MaterialField';

import MaterialTextBoxStyles from './MaterialTextBox.styles';

const MaterialTextBox = ({
  text,
  name,
  onChange,
  onBlur,
  isOptional,
  value,
  type,
  maxLength,
  error,
  touched
}) => {
  const filled = value.length !== 0 ? true : false;
  const showError = error && touched ? true : false;
  const errorId = `error${name}`;
  const materialProps = {
    text,
    name,
    isOptional,
    error,
    showError,
    errorId,
    filled
  };

  return (
    <MaterialField {...materialProps}>
      <MaterialTextBoxStyles
        showError={showError}
        name={name}
        id={name}
        value={value}
        type={type}
        onBlur={onBlur}
        onChange={event => {
          onChange(event);
        }}
        maxLength={maxLength}
        aria-required={!isOptional}
        aria-invalid={showError ? showError : undefined}
        aria-describedby={errorId}
      />
    </MaterialField>
  );
};

MaterialTextBox.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

MaterialTextBox.defaultProps = {
  isOptional: false,
  error: null,
  type: 'text',
  touched: null,
  maxLength: null
};

export default MaterialTextBox;
