/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import errorStyles from './Error.styles';

import SmsContext from '../../context/sms/smsContext';
import AuthContext from '../../context/auth/authContext';

const Error = props => {
  let title, description, link;
  const errStateProps = props.location.appState;
  if (errStateProps) {
    title = errStateProps.title;
    description = errStateProps.description;
    link = errStateProps.link;
  }
  const authContext = useContext(AuthContext);
  const smsContext = useContext(SmsContext);
  useEffect(() => {
    smsContext.clearState();
    // eslint-disable-next-line
  }, []);
  const numberValue = !errStateProps && <div className='number'>404</div>;
  const titleValue = title ? title : 'Page Not Found!';
  const descriptionValue = description
    ? description
    : `We're sorry, but we can't find the page you were looking for. Try one of these options:`;
  const linkValue = link ? (
    link
  ) : authContext.isAuthenticated ? (
    <Link to='/dashboard'>Go to Dashboard</Link>
  ) : (
    <Link to='/'>Go to Homepage</Link>
  );
  return (
    <div css={errorStyles}>
      <div className='title'>
        {numberValue}
        <div className='not-found'>{titleValue}</div>
      </div>
      <div>{descriptionValue}</div>
      <div className='link'>{linkValue}</div>
    </div>
  );
};

export default Error;
