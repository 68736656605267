/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

import formStyles from './Form.styles';

import ValidationMessage from '../Validation/ValidationMessage';
import Row from '../Grid/Row';
import Col from '../Grid/Col';
import Button from '../Button/Button';
import { AdditionalFields } from './AdditionalFields';
import MaterialTextBox from "../Material/MaterialTextBox";

const Form = ({ formikProps, serverError }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
    setFieldValue,
    setFieldTouched
  } = formikProps;

  const { Message, Date, Time } = values;

  const globalErrorMessage = serverError && (
    <ValidationMessage error={serverError} />
  );
  const messageRow = Message && (
    <AdditionalFields
      Message={Message}
      Date={Date}
      Time={Time}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
    />
  );

  return (
    <form css={formStyles} onSubmit={handleSubmit}>
      {globalErrorMessage}
      <Row>
        <Col xsFull>
          <MaterialTextBox
              name='Message'
              text='Message'
              onChange={handleChange}
              value={Message}
              error={errors.Message}
              touched={touched.Message}
              onBlur={handleBlur}
              maxLength={140}
          />
        </Col>
      </Row>
      {messageRow}
      <Row className='button-row' justify='space-evenly'>
        <Button
          value='Reset'
          isPrimary={false}
          isValid={true}
          onClick={() => resetForm()}
        />
        <Button
          value='Send Alert'
          type='submit'
          isPrimary={true}
          isValid={isValid}
        />
      </Row>
    </form>
  );
};

Form.propTypes = {
  formikProps: PropTypes.object.isRequired
};

Form.defaultProps = {
  serverError: false
};

export default Form;
