import { css } from '@emotion/core';
import { linkColors } from '../../theme';

const errorStyles = css`
  text-align: center;
  .title {
    font-weight: bold;
    .number {
      font-size: 5em;
      margin-bottom: 1rem;
    }
    .not-found {
      font-size: 1.5em;
      margin-bottom: 1.5rem;
    }
  }
  .link {
    margin-top: 1.5rem;
    a {
      color: ${linkColors.main};
      font-weight: 700;
      text-decoration: none;
      &:active,
      &:hover,
      &:focus {
        color: ${linkColors.state};
        text-decoration: none;
      }
    }
  }
`;

export default errorStyles;
