/** @jsx jsx */
import { jsx } from '@emotion/core';

import consentStyles from './ConsentTextMessage.styles';

// import Pdf from '../../assets/Electronic-Communications-Disclosure.pdf';

const ConsentTextMessage = () => {
  return (
    <div css={consentStyles}>
      <div className='consent-header'>
        Consent for Auto-Dialing, Pre-recorded Messages, Text Messaging
      </div>
      <p>
        By providing your number, you're also providing the following
        consents when you select the <strong>Submit</strong> button.
      </p>
      <p>
        I authorize the City of Milwaukee and its departments, service
        providers, collectors, and vendors, to contact me at such number using
        any means of communication, including, but not limited to, calls placed
        to this number using an automated dialing device, calls using
        prerecorded messages, and/or SMS text messages, regarding any current or
        future communications from the City of Milwaukee and its departments,
        service providers, collectors, and vendors, even if I will be charged by
        my service provider(s) for receiving such communications. Message and
        data rates may apply. Text STOP to (855) 757-3086 to cancel.
      </p>
      <div className='consent-header'>Unsubscribe/Contact</div>
      <p>To unsubscribe at any time, text stop.</p>
    </div>
  );
};

export default ConsentTextMessage;
