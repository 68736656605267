import React, { useContext, Fragment } from 'react';
import SmsContext from '../../context/sms/smsContext';
import Initial from '../Customer/Initial';
import Success from '../Customer/Success';

const Customer = () => {
  const smsContext = useContext(SmsContext);
  const { smsInfo } = smsContext;
  return <Fragment>{smsInfo ? <Success /> : <Initial />}</Fragment>;
};

export default Customer;
